// export const baseURL = "https://api.tassyer.com/api";
// export const baseURLFile = "https://api.tassyer.com";

export const baseURL = "https://api-crm.unmcrm.com/api";
export const baseURLFile = "https://api-crm.unmcrm.com";

// export const baseURL = "http://localhost:8000/api";
// export const baseURLFile = "http://localhost:8000";

export const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const validatePhone = (phone) => {
  const phonePattern = /^\+?\d{8,15}$/;
  return phonePattern.test(phone);
};

export const validateLocationX = (value) => {
  const num = parseFloat(value);
  // Check if it is a number and within the range of -180 to 180
  return !isNaN(num) && num >= -180 && num <= 180;
};

export const validateLocationY = (value) => {
  const num = parseFloat(value);
  return !isNaN(num) && num >= -90 && num <= 90;
};

export const SERVICESPECIALIST = [
  "Cardiologist (Heart Specialist)",
  "Dermatologist (Skin Specialist)",
  "Nephrologist (Kidney Specialist)",
  "Neurologist (Nervous System Specialist)",
  "Ophthalmologist (Eye Specialist)",
  "Orthopedist (Bone/Joint Specialist)",
  "Otorhinolaryngologist (ENT - Ear, Nose, Throat Specialist)",
  "Urologist (Urinary System Specialist)",
];

export const SERVICETYPE = [
  "GP",
  "ER",
  "HC",
  "Teleconsult",
  "Ambulance tansport",
  "Imaging",
  "Physiotherapy",
  "Psychiatrist",
  "Dentist",
  "Repatriation",
  "Tow-transport",
  "Private transport (Uber/taxis...)",
  "Specialists",
];

export const COUNTRIES = [
  {
    title: "",
    value: "",
    icon: "",
  },
  {
    title: "Afghanistan",
    value: "AF",
    icon: "🇦🇫",
  },
  {
    title: "Albania",
    value: "AL",
    icon: "🇦🇱",
  },
  {
    title: "Algeria",
    value: "DZ",
    icon: "🇩🇿",
  },
  {
    title: "American Samoa",
    value: "AS",
    icon: "🇦🇸",
  },
  {
    title: "Andorra",
    value: "AD",
    icon: "🇦🇩",
  },
  {
    title: "Angola",
    value: "AO",
    icon: "🇦🇴",
  },
  {
    title: "Anguilla",
    value: "AI",
    icon: "🇦🇮",
  },
  {
    title: "Argentina",
    value: "AR",
    icon: "🇦🇷",
  },
  {
    title: "Armenia",
    value: "AM",
    icon: "🇦🇲",
  },
  {
    title: "Aruba",
    value: "AW",
    icon: "🇦🇼",
  },
  {
    title: "Australia",
    value: "AU",
    icon: "🇦🇺",
  },
  {
    title: "Azerbaijan",
    value: "AZ",
    icon: "🇦🇿",
  },
  {
    title: "Bahamas",
    value: "BS",
    icon: "🇧🇸",
  },
  {
    title: "Bahrain",
    value: "BH",
    icon: "🇧🇭",
  },
  {
    title: "Bangladesh",
    value: "BD",
    icon: "🇧🇩",
  },
  {
    title: "Barbados",
    value: "BB",
    icon: "🇧🇧",
  },
  {
    title: "Belarus",
    value: "BY",
    icon: "🇧🇾",
  },
  {
    title: "Belgium",
    value: "BE",
    icon: "🇧🇪",
  },
  {
    title: "Belize",
    value: "BZ",
    icon: "🇧🇿",
  },
  {
    title: "Benin",
    value: "BJ",
    icon: "🇧🇯",
  },
  {
    title: "Bermuda",
    value: "BM",
    icon: "🇧🇲",
  },
  {
    title: "Bhutan",
    value: "BT",
    icon: "🇧🇹",
  },
  {
    title: "Bolivia",
    value: "BO",
    icon: "🇧🇴",
  },
  {
    title: "Bosnia and Herzegovina",
    value: "BA",
    icon: "🇧🇦",
  },
  {
    title: "Botswana",
    value: "BW",
    icon: "🇧🇼",
  },
  {
    title: "Brazil",
    value: "BR",
    icon: "🇧🇷",
  },
  {
    title: "British Virgin Islands",
    value: "VG",
    icon: "🇻🇬",
  },
  {
    title: "Brunei",
    value: "BN",
    icon: "🇧🇳",
  },
  {
    title: "Bulgaria",
    value: "BG",
    icon: "🇧🇬",
  },
  {
    title: "Burkina Faso",
    value: "BF",
    icon: "🇧🇫",
  },
  {
    title: "Burundi",
    value: "BI",
    icon: "🇧🇮",
  },
  {
    title: "Cambodia",
    value: "KH",
    icon: "🇰🇭",
  },
  {
    title: "Cameroon",
    value: "CM",
    icon: "🇨🇲",
  },
  {
    title: "Canada",
    value: "CA",
    icon: "🇨🇦",
  },
  {
    title: "Cape Verde",
    value: "CV",
    icon: "🇨🇻",
  },
  {
    title: "Cayman Islands",
    value: "KY",
    icon: "🇰🇾",
  },
  {
    title: "Central African Republic",
    value: "CF",
    icon: "🇨🇫",
  },
  {
    title: "Chad",
    value: "TD",
    icon: "🇹🇩",
  },
  {
    title: "Chile",
    value: "CL",
    icon: "🇨🇱",
  },
  {
    title: "China",
    value: "CN",
    icon: "🇨🇳",
  },
  {
    title: "Colombia",
    value: "CO",
    icon: "🇨🇴",
  },
  {
    title: "Comoros",
    value: "KM",
    icon: "🇰🇲",
  },
  {
    title: "Cook Islands",
    value: "CK",
    icon: "🇨🇰",
  },
  {
    title: "Costa Rica",
    value: "CR",
    icon: "🇨🇷",
  },
  {
    title: "Croatia",
    value: "HR",
    icon: "🇭🇷",
  },
  {
    title: "Cuba",
    value: "CU",
    icon: "🇨🇺",
  },
  {
    title: "Curacao",
    value: "CW",
    icon: "🇨🇼",
  },
  {
    title: "Cyprus",
    value: "CY",
    icon: "🇨🇾",
  },
  {
    title: "Czech Republic",
    value: "CZ",
    icon: "🇨🇿",
  },
  {
    title: "Democratic Republic of the Congo",
    value: "CD",
    icon: "🇨🇩",
  },
  {
    title: "Denmark",
    value: "DK",
    icon: "🇩🇰",
  },
  {
    title: "Djibouti",
    value: "DJ",
    icon: "🇩🇯",
  },
  {
    title: "Dominica",
    value: "DM",
    icon: "🇩🇲",
  },
  {
    title: "Dominican Republic",
    value: "DO",
    icon: "🇩🇴",
  },
  {
    title: "East Timor",
    value: "TL",
    icon: "🇹🇱",
  },
  {
    title: "Ecuador",
    value: "EC",
    icon: "🇪🇨",
  },
  {
    title: "Egypt",
    value: "EG",
    icon: "🇪🇬",
  },
  {
    title: "El Salvador",
    value: "SV",
    icon: "🇸🇻",
  },
  {
    title: "Eritrea",
    value: "ER",
    icon: "🇪🇷",
  },
  {
    title: "Estonia",
    value: "EE",
    icon: "🇪🇪",
  },
  {
    title: "Ethiopia",
    value: "ET",
    icon: "🇪🇹",
  },
  {
    title: "Faroe Islands",
    value: "FO",
    icon: "🇫🇴",
  },
  {
    title: "Fiji",
    value: "FJ",
    icon: "🇫🇯",
  },
  {
    title: "Finland",
    value: "FI",
    icon: "🇫🇮",
  },
  {
    title: "France",
    value: "FR",
    icon: "🇫🇷",
  },
  {
    title: "French Polynesia",
    value: "PF",
    icon: "🇵🇫",
  },
  {
    title: "Gabon",
    value: "GA",
    icon: "🇬🇦",
  },
  {
    title: "Gambia",
    value: "GM",
    icon: "🇬🇲",
  },
  {
    title: "Georgia",
    value: "GE",
    icon: "🇬🇪",
  },
  {
    title: "Germany",
    value: "DE",
    icon: "🇩🇪",
  },
  {
    title: "Ghana",
    value: "GH",
    icon: "🇬🇭",
  },
  {
    title: "Greece",
    value: "GR",
    icon: "🇬🇷",
  },
  {
    title: "Greenland",
    value: "GL",
    icon: "🇬🇱",
  },
  {
    title: "Grenada",
    value: "GD",
    icon: "🇬🇩",
  },
  {
    title: "Guam",
    value: "GU",
    icon: "🇬🇺",
  },
  {
    title: "Guatemala",
    value: "GT",
    icon: "🇬🇹",
  },
  {
    title: "Guernsey",
    value: "GG",
    icon: "🇬🇬",
  },
  {
    title: "Guinea",
    value: "GN",
    icon: "🇬🇳",
  },
  {
    title: "Guinea-Bissau",
    value: "GW",
    icon: "🇬🇼",
  },
  {
    title: "Guyana",
    value: "GY",
    icon: "🇬🇾",
  },
  {
    title: "Haiti",
    value: "HT",
    icon: "🇭🇹",
  },
  {
    title: "Honduras",
    value: "HN",
    icon: "🇭🇳",
  },
  {
    title: "Hong Kong",
    value: "HK",
    icon: "🇭🇰",
  },
  {
    title: "Hungary",
    value: "HU",
    icon: "🇭🇺",
  },
  {
    title: "Iceland",
    value: "IS",
    icon: "🇮🇸",
  },
  {
    title: "India",
    value: "IN",
    icon: "🇮🇳",
  },
  {
    title: "Indonesia",
    value: "ID",
    icon: "🇮🇩",
  },
  {
    title: "Iran",
    value: "IR",
    icon: "🇮🇷",
  },
  {
    title: "Iraq",
    value: "IQ",
    icon: "🇮🇶",
  },
  {
    title: "Ireland",
    value: "IE",
    icon: "🇮🇪",
  },
  {
    title: "Isle of Man",
    value: "IM",
    icon: "🇮🇲",
  },
  {
    title: "Israel",
    value: "IL",
    icon: "🇮🇱",
  },
  {
    title: "Italy",
    value: "IT",
    icon: "🇮🇹",
  },
  {
    title: "Ivory Coast",
    value: "CI",
    icon: "🇨🇮",
  },
  {
    title: "Jamaica",
    value: "JM",
    icon: "🇯🇲",
  },
  {
    title: "Japan",
    value: "JP",
    icon: "🇯🇵",
  },
  {
    title: "Jersey",
    value: "JE",
    icon: "🇯🇪",
  },
  {
    title: "Jordan",
    value: "JO",
    icon: "🇯🇴",
  },
  {
    title: "Kazakhstan",
    value: "KZ",
    icon: "🇰🇿",
  },
  {
    title: "Kenya",
    value: "KE",
    icon: "🇰🇪",
  },
  {
    title: "Kiribati",
    value: "KI",
    icon: "🇰🇮",
  },
  {
    title: "Kosovo",
    value: "XK",
    icon: "🇽🇰",
  },
  {
    title: "Kuwait",
    value: "KW",
    icon: "🇰🇼",
  },
  {
    title: "Kyrgyzstan",
    value: "KG",
    icon: "🇰🇬",
  },
  {
    title: "Laos",
    value: "LA",
    icon: "🇱🇦",
  },
  {
    title: "Latvia",
    value: "LV",
    icon: "🇱🇻",
  },
  {
    title: "Lebanon",
    value: "LB",
    icon: "🇱🇧",
  },
  {
    title: "Lesotho",
    value: "LS",
    icon: "🇱🇸",
  },
  {
    title: "Liberia",
    value: "LR",
    icon: "🇱🇷",
  },
  {
    title: "Libya",
    value: "LY",
    icon: "🇱🇾",
  },
  {
    title: "Liechtenstein",
    value: "LI",
    icon: "🇱🇮",
  },
  {
    title: "Lithuania",
    value: "LT",
    icon: "🇱🇹",
  },
  {
    title: "Luxembourg",
    value: "LU",
    icon: "🇱🇺",
  },
  {
    title: "Macau",
    value: "MO",
    icon: "🇲🇴",
  },
  {
    title: "Macedonia",
    value: "MK",
    icon: "🇲🇰",
  },
  {
    title: "Madagascar",
    value: "MG",
    icon: "🇲🇬",
  },
  {
    title: "Malawi",
    value: "MW",
    icon: "🇲🇼",
  },
  {
    title: "Malaysia",
    value: "MY",
    icon: "🇲🇾",
  },
  {
    title: "Maldives",
    value: "MV",
    icon: "🇲🇻",
  },
  {
    title: "Mali",
    value: "ML",
    icon: "🇲🇱",
  },
  {
    title: "Malta",
    value: "MT",
    icon: "🇲🇹",
  },
  {
    title: "Marshall Islands",
    value: "MH",
    icon: "🇲🇭",
  },
  {
    title: "Mauritania",
    value: "MR",
    icon: "🇲🇷",
  },
  {
    title: "Mauritius",
    value: "MU",
    icon: "🇲🇺",
  },
  {
    title: "Mayotte",
    value: "YT",
    icon: "🇾🇹",
  },
  {
    title: "Mexico",
    value: "MX",
    icon: "🇲🇽",
  },
  {
    title: "Micronesia",
    value: "FM",
    icon: "🇫🇲",
  },
  {
    title: "Moldova",
    value: "MD",
    icon: "🇲🇩",
  },
  {
    title: "Monaco",
    value: "MC",
    icon: "🇲🇨",
  },
  {
    title: "Mongolia",
    value: "MN",
    icon: "🇲🇳",
  },
  {
    title: "Montenegro",
    value: "ME",
    icon: "🇲🇪",
  },
  {
    title: "Morocco",
    value: "MA",
    icon: "🇲🇦",
  },
  {
    title: "Mozambique",
    value: "MZ",
    icon: "🇲🇿",
  },
  {
    title: "Myanmar",
    value: "MM",
    icon: "🇲🇲",
  },
  {
    title: "Namibia",
    value: "NA",
    icon: "🇳🇦",
  },
  {
    title: "Nepal",
    value: "NP",
    icon: "🇳🇵",
  },
  {
    title: "Netherlands",
    value: "NL",
    icon: "🇳🇱",
  },
  {
    title: "Netherlands Antilles",
    value: "AN",
    icon: "🇦🇳",
  },
  {
    title: "New Caledonia",
    value: "NC",
    icon: "🇳🇨",
  },
  {
    title: "New Zealand",
    value: "NZ",
    icon: "🇳🇿",
  },
  {
    title: "Nicaragua",
    value: "NI",
    icon: "🇳🇮",
  },
  {
    title: "Niger",
    value: "NE",
    icon: "🇳🇪",
  },
  {
    title: "Nigeria",
    value: "NG",
    icon: "🇳🇬",
  },
  {
    title: "North Korea",
    value: "KP",
    icon: "🇰🇵",
  },
  {
    title: "Northern Mariana Islands",
    value: "MP",
    icon: "🇲🇵",
  },
  {
    title: "Norway",
    value: "NO",
    icon: "🇳🇴",
  },
  {
    title: "Oman",
    value: "OM",
    icon: "🇴🇲",
  },
  {
    title: "Pakistan",
    value: "PK",
    icon: "🇵🇰",
  },
  {
    title: "Palestine",
    value: "PS",
    icon: "🇵🇸",
  },
  {
    title: "Panama",
    value: "PA",
    icon: "🇵🇦",
  },
  {
    title: "Papua New Guinea",
    value: "PG",
    icon: "🇵🇬",
  },
  {
    title: "Paraguay",
    value: "PY",
    icon: "🇵🇾",
  },
  {
    title: "Peru",
    value: "PE",
    icon: "🇵🇪",
  },
  {
    title: "Philippines",
    value: "PH",
    icon: "🇵🇭",
  },
  {
    title: "Poland",
    value: "PL",
    icon: "🇵🇱",
  },
  {
    title: "Portugal",
    value: "PT",
    icon: "🇵🇹",
  },
  {
    title: "Puerto Rico",
    value: "PR",
    icon: "🇵🇷",
  },
  {
    title: "Qatar",
    value: "QA",
    icon: "🇶🇦",
  },
  {
    title: "Republic of the Congo",
    value: "CG",
    icon: "🇨🇬",
  },
  {
    title: "Reunion",
    value: "RE",
    icon: "🇷🇪",
  },
  {
    title: "Romania",
    value: "RO",
    icon: "🇷🇴",
  },
  {
    title: "Russia",
    value: "RU",
    icon: "🇷🇺",
  },
  {
    title: "Rwanda",
    value: "RW",
    icon: "🇷🇼",
  },
  {
    title: "Saint Kitts and Nevis",
    value: "KN",
    icon: "🇰🇳",
  },
  {
    title: "Saint Lucia",
    value: "LC",
    icon: "🇱🇨",
  },
  {
    title: "Saint Martin",
    value: "MF",
    icon: "🇲🇫",
  },
  {
    title: "Saint Pierre and Miquelon",
    value: "PM",
    icon: "🇵🇲",
  },
  {
    title: "Saint Vincent and the Grenadines",
    value: "VC",
    icon: "🇻🇨",
  },
  {
    title: "Samoa",
    value: "WS",
    icon: "🇼🇸",
  },
  {
    title: "San Marino",
    value: "SM",
    icon: "🇸🇲",
  },
  {
    title: "Sao Tome and Principe",
    value: "ST",
    icon: "🇸🇹",
  },
  {
    title: "Saudi Arabia",
    value: "SA",
    icon: "🇸🇦",
  },
  {
    title: "Senegal",
    value: "SN",
    icon: "🇸🇳",
  },
  {
    title: "Serbia",
    value: "RS",
    icon: "🇷🇸",
  },
  {
    title: "Seychelles",
    value: "SC",
    icon: "🇸🇨",
  },
  {
    title: "Sierra Leone",
    value: "SL",
    icon: "🇸🇱",
  },
  {
    title: "Singapore",
    value: "SG",
    icon: "🇸🇬",
  },
  {
    title: "Sint Maarten",
    value: "SX",
    icon: "🇸🇽",
  },
  {
    title: "Slovakia",
    value: "SK",
    icon: "🇸🇰",
  },
  {
    title: "Slovenia",
    value: "SI",
    icon: "🇸🇮",
  },
  {
    title: "Solomon Islands",
    value: "SB",
    icon: "🇸🇧",
  },
  {
    title: "Somalia",
    value: "SO",
    icon: "🇸🇴",
  },
  {
    title: "South Africa",
    value: "ZA",
    icon: "🇿🇦",
  },
  {
    title: "South Korea",
    value: "KR",
    icon: "🇰🇷",
  },
  {
    title: "South Sudan",
    value: "SS",
    icon: "🇸🇸",
  },
  {
    title: "Spain",
    value: "ES",
    icon: "🇪🇸",
  },
  {
    title: "Sri Lanka",
    value: "LK",
    icon: "🇱🇰",
  },
  {
    title: "Sudan",
    value: "SD",
    icon: "🇸🇩",
  },
  {
    title: "Suriname",
    value: "SR",
    icon: "🇸🇷",
  },
  {
    title: "Swaziland",
    value: "SZ",
    icon: "🇸🇿",
  },
  {
    title: "Sweden",
    value: "SE",
    icon: "🇸🇪",
  },
  {
    title: "Switzerland",
    value: "CH",
    icon: "🇨🇭",
  },
  {
    title: "Syria",
    value: "SY",
    icon: "🇸🇾",
  },
  {
    title: "Taiwan",
    value: "TW",
    icon: "🇹🇼",
  },
  {
    title: "Tajikistan",
    value: "TJ",
    icon: "🇹🇯",
  },
  {
    title: "Tanzania",
    value: "TZ",
    icon: "🇹🇿",
  },
  {
    title: "Thailand",
    value: "TH",
    icon: "🇹🇭",
  },
  {
    title: "Togo",
    value: "TG",
    icon: "🇹🇬",
  },
  {
    title: "Tonga",
    value: "TO",
    icon: "🇹🇴",
  },
  {
    title: "Trinidad and Tobago",
    value: "TT",
    icon: "🇹🇹",
  },
  {
    title: "Tunisia",
    value: "TN",
    icon: "🇹🇳",
  },
  {
    title: "Turkey",
    value: "TR",
    icon: "🇹🇷",
  },
  {
    title: "Turkmenistan",
    value: "TM",
    icon: "🇹🇲",
  },
  {
    title: "Turks and Caicos Islands",
    value: "TC",
    icon: "🇹🇨",
  },
  {
    title: "Tuvalu",
    value: "TV",
    icon: "🇹🇻",
  },
  {
    title: "U.S. Virgin Islands",
    value: "VI",
    icon: "🇻🇮",
  },
  {
    title: "Uganda",
    value: "UG",
    icon: "🇺🇬",
  },
  {
    title: "Ukraine",
    value: "UA",
    icon: "🇺🇦",
  },
  {
    title: "United Arab Emirates",
    value: "AE",
    icon: "🇦🇪",
  },
  {
    title: "United Kingdom",
    value: "GB",
    icon: "🇬🇧",
  },
  {
    title: "United States",
    value: "US",
    icon: "🇺🇸",
  },
  {
    title: "Uruguay",
    value: "UY",
    icon: "🇺🇾",
  },
  {
    title: "Uzbekistan",
    value: "UZ",
    icon: "🇺🇿",
  },
  {
    title: "Vanuatu",
    value: "VU",
    icon: "🇻🇺",
  },
  {
    title: "Venezuela",
    value: "VE",
    icon: "🇻🇪",
  },
  {
    title: "Vietnam",
    value: "VN",
    icon: "🇻🇳",
  },
  {
    title: "Western Sahara",
    value: "EH",
    icon: "🇪🇭",
  },
  {
    title: "Yemen",
    value: "YE",
    icon: "🇾🇪",
  },
  {
    title: "Zambia",
    value: "ZM",
    icon: "🇿🇲",
  },
  {
    title: "Zimbabwe",
    value: "ZW",
    icon: "🇿🇼",
  },
];
