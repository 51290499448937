import axios from "../../axios";
import {
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_LIST_FAIL,
  //
  PROVIDER_ADD_REQUEST,
  PROVIDER_ADD_SUCCESS,
  PROVIDER_ADD_FAIL,
  //
  PROVIDER_DETAIL_REQUEST,
  PROVIDER_DETAIL_SUCCESS,
  PROVIDER_DETAIL_FAIL,
  //
  PROVIDER_UPDATE_REQUEST,
  PROVIDER_UPDATE_SUCCESS,
  PROVIDER_UPDATE_FAIL,
  //
  PROVIDER_DELETE_REQUEST,
  PROVIDER_DELETE_SUCCESS,
  PROVIDER_DELETE_FAIL,
  //
} from "../constants/providerConstants";

export const updateProvider = (id, provider) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(
      `/providers/update/${id}/`,
      provider,
      config
    );

    dispatch({
      type: PROVIDER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: PROVIDER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete provider
export const deleteProvider = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/providers/delete/${id}/`, config);

    dispatch({
      type: PROVIDER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: PROVIDER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// create provider
export const createNewProvider = (provider) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/providers/create-new-provider/`,
      provider,
      config
    );

    dispatch({
      type: PROVIDER_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: PROVIDER_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// detail case
export const detailProvider = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/providers/detail/${id}/`, config);

    dispatch({
      type: PROVIDER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: PROVIDER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list cases
export const providersList =
  (
    page,
    filterName = "",
    filterType = "",
    filterCity = "",
    filterCountry = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PROVIDER_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/providers/?page=${page}&filtername=${filterName}&filtertype=${filterType}&filtercity=${filterCity}&filtercountry=${filterCountry}`,
        config
      );

      dispatch({
        type: PROVIDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: PROVIDER_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };
