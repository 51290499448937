import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { toast } from "react-toastify";
import { createNewProvider } from "../../redux/actions/providerActions";
import axios from "axios";
import Select from "react-select";
import {
  COUNTRIES,
  SERVICESPECIALIST,
  SERVICETYPE,
  validateEmail,
  validateLocationX,
  validateLocationY,
  validatePhone,
} from "../../constants";
import GoogleComponent from "react-google-autocomplete";

function AddProviderScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [serviceType, setServiceType] = useState("");
  const [serviceTypeError, setServiceTypeError] = useState("");

  const [serviceSpecialist, setServiceSpecialist] = useState("");
  const [serviceSpecialistError, setServiceSpecialistError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [cityVl, setCityVl] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [locationX, setLocationX] = useState(0);
  const [locationXError, setLocationXError] = useState("");

  const [locationY, setLocationY] = useState(0);
  const [locationYError, setLocationYError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const providerAdd = useSelector((state) => state.addNewProvider);
  const { loadingProviderAdd, errorProviderAdd, successProviderAdd } =
    providerAdd;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successProviderAdd) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setAddress("");
      setCountry("");
      setCity("");
      setLocationX(0);
      setLocationY(0);
      setServiceType("");
      setServiceSpecialist("");

      setFirstNameError("");
      setLastNameError("");
      setEmailError("");
      setPhoneError("");
      setAddressError("");
      setCountryError("");
      setCityError("");
      setLocationXError("");
      setLocationYError("");
      setServiceTypeError("");
      setServiceSpecialistError("");
    }
  }, [successProviderAdd]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <a href="/providers-list">
            <div className="flex flex-row  items-center hover:text-black ">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <div className="">Providers List</div>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Create New Provider</div>
        </div>
        {/*  */}
        <div className="py-5 px-4 flex justify-between">
          <h4 className=" uppercase font-semibold text-black dark:text-white">
            New Provider
          </h4>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="my-2 bg-white py-4 px-2 rounded-md">
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  First Name <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      firstNameError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(v) => setFirstName(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {firstNameError ? firstNameError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pl-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs mb-1">
                  Last Name
                </div>
                <div>
                  <input
                    className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(v) => setLastName(v.target.value)}
                  />
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Email
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      emailError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {emailError ? emailError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pl-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs mb-1">
                  Phone
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      phoneError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="phone"
                    placeholder="Phone"
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {phoneError ? phoneError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Service Type <strong className="text-danger">*</strong>
                </div>
                <div>
                  <Select
                    value={serviceType}
                    onChange={(option) => {
                      setServiceType(option);
                      setServiceSpecialist("");
                    }}
                    className="text-sm"
                    options={SERVICETYPE.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    placeholder="Select a Service Type..."
                    isSearchable
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        background: "#fff",
                        border: serviceTypeError
                          ? "1px solid #d34053"
                          : "1px solid #F1F3FF",
                        boxShadow: state.isFocused ? "none" : "none",
                        "&:hover": {
                          border: "1px solid #F1F3FF",
                        },
                      }),
                      option: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                      singleValue: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                  />

                  <div className=" text-[8px] text-danger">
                    {serviceTypeError ? serviceTypeError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              {serviceType !== "" && serviceType.value === "Specialists" ? (
                <div className="md:w-1/2 w-full  md:pr-1 my-1">
                  <div className="text-[#000000bf] font-bold text-xs  mb-1">
                    Service Specialist{" "}
                    <strong className="text-danger">*</strong>
                  </div>
                  <div>
                    <Select
                      value={serviceSpecialist}
                      onChange={(option) => {
                        setServiceSpecialist(option);
                      }}
                      className="text-sm"
                      options={SERVICESPECIALIST.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                      placeholder="Select a Specialist..."
                      isSearchable
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          background: "#fff",
                          border: serviceSpecialistError
                            ? "1px solid #d34053"
                            : "1px solid #F1F3FF",
                          boxShadow: state.isFocused ? "none" : "none",
                          "&:hover": {
                            border: "1px solid #F1F3FF",
                          },
                        }),
                        option: (base) => ({
                          ...base,
                          display: "flex",
                          alignItems: "center",
                        }),
                        singleValue: (base) => ({
                          ...base,
                          display: "flex",
                          alignItems: "center",
                        }),
                      }}
                    />
                    <div className=" text-[8px] text-danger">
                      {serviceSpecialistError ? serviceSpecialistError : ""}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="w-full  md:pl-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Address <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      addressError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Address"
                    value={address}
                    onChange={(v) => setAddress(v.target.value)}
                  />

                  <div className=" text-[8px] text-danger">
                    {addressError ? addressError : ""}
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Country
                </div>
                <div>
                  <Select
                    value={country}
                    onChange={(option) => {
                      setCountry(option);
                    }}
                    className="text-sm"
                    options={COUNTRIES.map((country) => ({
                      value: country.title,
                      label: (
                        <div
                          className={`${
                            country.title === "" ? "py-2" : ""
                          } flex flex-row items-center`}
                        >
                          <span className="mr-2">{country.icon}</span>
                          <span>{country.title}</span>
                        </div>
                      ),
                    }))}
                    placeholder="Select a country..."
                    isSearchable
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        background: "#fff",
                        border: countryError
                          ? "1px solid #d34053"
                          : "1px solid #F1F3FF",
                        boxShadow: state.isFocused ? "none" : "none",
                        "&:hover": {
                          border: "1px solid #F1F3FF",
                        },
                      }),
                      option: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                      singleValue: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                  />
                  <div className=" text-[8px] text-danger">
                    {countryError ? countryError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pl-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs mb-1">
                  City
                </div>
                <div>
                  <GoogleComponent
                    apiKey="AIzaSyBtrUF56GBpFDiaXyLLGfdO8nIK5NWXUIU"
                    className={` outline-none border ${
                      cityError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    onChange={(v) => {
                      setCity(v.target.value);
                    }}
                    onPlaceSelected={(place) => {
                      if (place && place.geometry) {
                        setCity(place.formatted_address ?? "");
                        setCityVl(place.formatted_address ?? "");
                        //   const latitude = place.geometry.location.lat();
                        //   const longitude = place.geometry.location.lng();
                        //   setLocationX(latitude ?? "");
                        //   setLocationY(longitude ?? "");
                      }
                    }}
                    defaultValue={city}
                    types={["city"]}
                    language="en"
                  />

                  <div className=" text-[8px] text-danger">
                    {cityError ? cityError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Location X <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      locationXError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="number"
                    step={0.01}
                    placeholder="Location X"
                    value={locationX}
                    onChange={(v) => setLocationX(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {locationXError ? locationXError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pl-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs mb-1">
                  Location Y <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      locationYError ? "border-danger" : "border-[#F1F3FF]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="number"
                    step={0.01}
                    placeholder="Location Y"
                    value={locationY}
                    onChange={(v) => setLocationY(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger">
                    {locationYError ? locationYError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="my-3 ">
              <div className="flex flex-row items-center justify-end my-3">
                <a
                  href="/providers-list"
                  className="bg-white text-[#0388A6] text-sm px-5 py-3 rounded-full mr-3"
                >
                  Back
                </a>
                <button
                  onClick={async () => {
                    var check = true;
                    setFirstNameError("");
                    setAddressError("");
                    setServiceTypeError("");
                    setServiceSpecialistError("");
                    setLocationXError("");
                    setLocationYError("");
                    setPhoneError("");
                    setEmailError("");

                    if (firstName === "") {
                      setFirstNameError("These fields are required.");
                      check = false;
                    }

                    if (email !== "" && !validateEmail(email)) {
                      setEmailError(
                        "Invalid email address. Please correct it."
                      );
                      check = false;
                    }
                    if (phone !== "" && !validatePhone(phone)) {
                      setPhoneError("Invalid phone number. Please correct it.");
                      check = false;
                    }

                    if (serviceType === "" || serviceType.value === "") {
                      setServiceTypeError("These fields are required.");
                      check = false;
                    } else if (
                      serviceType.value === "Specialists" &&
                      (serviceSpecialist === "" ||
                        serviceSpecialist.value === "")
                    ) {
                      setServiceSpecialistError("These fields are required.");
                      check = false;
                    }

                    if (address === "") {
                      setAddressError("These fields are required.");
                      check = false;
                    }

                    if (locationX === "") {
                      setLocationXError("These fields are required.");
                      check = false;
                    } else if (!validateLocationX(locationX)) {
                      setLocationXError(
                        "Please enter a valid longitude (-180 to 180)."
                      );
                      check = false;
                    }
                    if (locationY === "") {
                      setLocationYError("These fields are required.");
                      check = false;
                    } else if (!validateLocationY(locationY)) {
                      setLocationYError(
                        "Please enter a valid latitude (-90 to 90)."
                      );
                      check = false;
                    }

                    if (check) {
                      setLoadEvent(true);
                      await dispatch(
                        createNewProvider({
                          first_name: firstName,
                          last_name: lastName ?? "",
                          full_name: firstName + " " + lastName,
                          service_type: serviceType.value ?? "",
                          service_specialist: serviceSpecialist.value ?? "",
                          email: email ?? "",
                          phone: phone ?? "",
                          address: address,
                          country: country.value ?? "",
                          city: city ?? "",
                          location_x: locationX,
                          location_y: locationY,
                        })
                      ).then(() => {});
                      setLoadEvent(false);
                    } else {
                      toast.error(
                        "Some fields are empty or invalid. please try again"
                      );
                    }
                  }}
                  className="text-white bg-[#0388A6] text-sm px-5 py-3 rounded-full"
                >
                  {loadingProviderAdd ? "Loading ..." : "Create Provider"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default AddProviderScreen;
