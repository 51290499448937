import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { casesList, deleteCase } from "../../redux/actions/caseActions";
import ConfirmationModal from "../../components/ConfirmationModal";
import Paginate from "../../components/Paginate";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import DefaultLayout from "../../layouts/DefaultLayout";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Select from "react-select";
import { getListCoordinators } from "../../redux/actions/userActions";
import { providersList } from "../../redux/actions/providerActions";
import { getInsuranesList } from "../../redux/actions/insuranceActions";
import { UAParser } from "ua-parser-js";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

function DashboardScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const [providerMapSelect, setProviderMapSelect] = useState(null);
  const [isOpenMap, setIsOpenMap] = useState(false);

  const [idFilter, setIdFilter] = useState("");
  const [ciaIdFilter, setCiaIdFilter] = useState("");
  const [patientFilter, setPatientFilter] = useState("");
  const [insuranceFilter, setInsuranceFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [providerFilter, setProviderFilter] = useState("");
  const [coordinationFilter, setCoordinatorFilter] = useState("");
  const [statusFilter, setStatusrFilter] = useState("");

  const [isDelete, setIsDelete] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  const [caseId, setCaseId] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listCases = useSelector((state) => state.caseList);
  const { cases, loadingCases, errorCases, pages } = listCases;

  const caseDelete = useSelector((state) => state.deleteCase);
  const { loadingCaseDelete, errorCaseDelete, successCaseDelete } = caseDelete;

  const listProviders = useSelector((state) => state.providerList);
  const { providers, loadingProviders, errorProviders } = listProviders;

  const listInsurances = useSelector((state) => state.insuranceList);
  const { insurances, loadingInsurances, errorInsurances } = listInsurances;

  const listCoordinators = useSelector((state) => state.coordinatorsList);
  const { coordinators, loadingCoordinators, errorCoordinators } =
    listCoordinators;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      const parser = new UAParser();
      const result = parser.getResult();

      const browser = result.browser.name || "Unknown browser";
      const device =
        result.device.model || result.device.type || "Unknown device";

      console.log(result);
      console.log(browser);
      console.log(device);

      dispatch(
        casesList(
          page,
          "",
          idFilter,
          patientFilter,
          statusFilter,
          insuranceFilter !== "" ? insuranceFilter.value ?? "" : "",
          providerFilter !== "" ? providerFilter.value ?? "" : "",
          coordinationFilter,
          typeFilter,
          ciaIdFilter
        )
      );
      dispatch(getListCoordinators("0"));
      dispatch(providersList("0"));
      dispatch(getInsuranesList("0"));
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    page,
    idFilter,
    patientFilter,
    statusFilter,
    insuranceFilter,
    providerFilter,
    coordinationFilter,
    typeFilter,
  ]);

  useEffect(() => {
    if (successCaseDelete) {
      dispatch(casesList("1"));
    }
  }, [successCaseDelete]);

  const formatDate = (dateString) => {
    if (dateString && dateString !== "") {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };

  const caseStatus = (casestatus) => {
    switch (casestatus) {
      case "pending-coordination":
        return "Pending Coordination";
      case "coordinated-missing-m-r":
        return "Coordinated, Missing M.R.";
      case "coordinated-missing-invoice":
        return "Coordinated, Missing Invoice";
      case "waiting-for-insurance-authorization":
        return "Waiting for Insurance Authorization";
      case "coordinated-patient-not-seen-yet":
        return "Coordinated, Patient not seen yet";
      case "fully-coordinated":
        return "Fully Coordinated";
      default:
        return casestatus;
    }
  };

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke px-2 pt-6 pb-2.5 shadow-default   dark:bg-boxdark ">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex md:flex-row flex-col justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Cases list
            </h4>
            <div className="flex flex-row justify-end">
              <a
                href="/cases-list/add"
                className="px-4 py-3 rounded-full text-white bg-[#0388A6] flex flex-row text-xs items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>

                <div className="mx-2">Create new case</div>
              </a>
            </div>
          </div>
          <div className="flex md:flex-row flex-col items-center">
            <div className="flex flex-row  items-center">
              <div className="m-1 ">
                <input
                  className="px-5 py-2 rounded-full bg-white text-sm text-[#687779] outline-none"
                  placeholder="Search ID Case"
                  type="text"
                  value={idFilter}
                  onChange={(v) => {
                    setIdFilter(v.target.value);
                    dispatch(
                      casesList(
                        "1",
                        "",
                        v.target.value,
                        patientFilter,
                        statusFilter,
                        insuranceFilter !== ""
                          ? insuranceFilter.value ?? ""
                          : "",
                        providerFilter !== "" ? providerFilter.value ?? "" : "",
                        coordinationFilter,
                        typeFilter,
                        ciaIdFilter
                      )
                    );
                  }}
                />
              </div>
              <div className="m-1 ">
                <input
                  className="px-5 py-2 rounded-full bg-white text-sm text-[#687779] outline-none"
                  placeholder="Search CIA ID"
                  type="text"
                  value={ciaIdFilter}
                  onChange={(v) => {
                    setCiaIdFilter(v.target.value);
                    dispatch(
                      casesList(
                        "1",
                        "",
                        idFilter,
                        patientFilter,
                        statusFilter,
                        insuranceFilter !== ""
                          ? insuranceFilter.value ?? ""
                          : "",
                        providerFilter !== "" ? providerFilter.value ?? "" : "",
                        coordinationFilter,
                        typeFilter,
                        v.target.value
                      )
                    );
                  }}
                />
              </div>
            </div>
            {/*  */}
            <div className="flex flex-row  items-center">
              <div className="m-1 ">
                <input
                  className="px-5 py-2 rounded-full bg-white text-sm text-[#687779] outline-none"
                  placeholder="Patient Name"
                  type="text"
                  value={patientFilter}
                  onChange={(v) => {
                    setPatientFilter(v.target.value);
                    dispatch(
                      casesList(
                        "1",
                        "",
                        idFilter,
                        v.target.value,
                        statusFilter,
                        insuranceFilter !== ""
                          ? insuranceFilter.value ?? ""
                          : "",
                        providerFilter !== "" ? providerFilter.value ?? "" : "",
                        coordinationFilter,
                        typeFilter,
                        ciaIdFilter
                      )
                    );
                  }}
                />
              </div>
              <div className="m-1  ">
                <select
                  value={typeFilter}
                  onChange={(v) => {
                    setTypeFilter(v.target.value);
                    dispatch(
                      casesList(
                        "1",
                        "",
                        idFilter,
                        patientFilter,
                        statusFilter,
                        insuranceFilter !== ""
                          ? insuranceFilter.value ?? ""
                          : "",
                        providerFilter !== "" ? providerFilter.value ?? "" : "",
                        coordinationFilter,
                        v.target.value,
                        ciaIdFilter
                      )
                    );
                  }}
                  className="px-5 py-2 rounded-full bg-white text-sm text-[#687779] outline-none"
                >
                  <option value={""}>Select Type</option>
                  <option value={"Medical"}>Medical</option>
                  <option value={"Technical"}>Technical</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col items-center">
            <div className="flex flex-row items-center">
              <div className="m-1  ">
                <select
                  value={statusFilter}
                  onChange={(v) => {
                    setStatusrFilter(v.target.value);
                    dispatch(
                      casesList(
                        "1",
                        "",
                        idFilter,
                        patientFilter,
                        v.target.value,
                        insuranceFilter !== ""
                          ? insuranceFilter.value ?? ""
                          : "",
                        providerFilter !== "" ? providerFilter.value ?? "" : "",
                        coordinationFilter,
                        typeFilter,
                        ciaIdFilter
                      )
                    );
                  }}
                  className="px-5 py-2 rounded-full bg-white text-sm text-[#687779] outline-none"
                >
                  <option value={""}>Select Status</option>
                  <option value={"pending-coordination"}>
                    Pending Coordination
                  </option>
                  <option value={"coordinated-missing-m-r"}>
                    Coordinated, Missing M.R.
                  </option>
                  <option value={"coordinated-missing-invoice"}>
                    Coordinated, Missing Invoice
                  </option>
                  <option value={"waiting-for-insurance-authorization"}>
                    Waiting for Insurance Authorization
                  </option>
                  <option value={"coordinated-patient-not-seen-yet"}>
                    Coordinated, Patient not seen yet
                  </option>
                  <option value={"fully-coordinated"}>Fully Coordinated</option>
                </select>
              </div>
              <div className="m-1">
                <Select
                  value={insuranceFilter}
                  onChange={(option) => {
                    setInsuranceFilter(option);
                    if (option.value) {
                      dispatch(
                        casesList(
                          "1",
                          "",
                          idFilter,
                          patientFilter,
                          statusFilter,
                          option.value,
                          providerFilter !== ""
                            ? providerFilter.value ?? ""
                            : "",
                          coordinationFilter,
                          typeFilter,
                          ciaIdFilter
                        )
                      );
                    } else {
                      dispatch(
                        casesList(
                          "1",
                          "",
                          idFilter,
                          patientFilter,
                          statusFilter,
                          "",
                          providerFilter !== ""
                            ? providerFilter.value ?? ""
                            : "",
                          coordinationFilter,
                          typeFilter,
                          ciaIdFilter
                        )
                      );
                    }
                  }}
                  options={insurances?.map((assurance) => ({
                    value: assurance.id,
                    label: assurance.assurance_name || "",
                  }))}
                  filterOption={(option, inputValue) =>
                    option.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  className="px-5 rounded-full bg-white text-sm text-[#687779] outline-none"
                  placeholder="Select Insurance..."
                  isSearchable
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      background: "#fff",
                      border: "none",
                      boxShadow: state.isFocused ? "none" : "none",
                      "&:hover": {
                        border: "none",
                      },
                      minWidth: "10rem",
                    }),
                    option: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                />
              </div>
            </div>
            {/*  */}
            <div className="flex flex-row items-center">
              <div className="m-1">
                <Select
                  value={providerFilter}
                  onChange={(option) => {
                    setProviderFilter(option);
                    if (option.value) {
                      dispatch(
                        casesList(
                          "1",
                          "",
                          idFilter,
                          patientFilter,
                          statusFilter,
                          insuranceFilter,
                          option.value,
                          coordinationFilter,
                          typeFilter,
                          ciaIdFilter
                        )
                      );
                    } else {
                      dispatch(
                        casesList(
                          "1",
                          "",
                          idFilter,
                          patientFilter,
                          statusFilter,
                          insuranceFilter,
                          "",
                          coordinationFilter,
                          typeFilter,
                          ciaIdFilter
                        )
                      );
                    }
                  }}
                  options={providers?.map((provider) => ({
                    value: provider.id,
                    label: provider.full_name || "",
                  }))}
                  filterOption={(option, inputValue) =>
                    option.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  className="px-5 rounded-full bg-white text-sm text-[#687779] outline-none"
                  placeholder="Select Provider..."
                  isSearchable
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      background: "#fff",
                      border: "none",
                      boxShadow: state.isFocused ? "none" : "none",
                      "&:hover": {
                        border: "none",
                      },
                      minWidth: "10rem",
                    }),
                    option: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                />
              </div>
              <div className="m-1">
                <button
                  onClick={() => {
                    setIdFilter("");
                    setInsuranceFilter("");
                    setProviderFilter("");
                    setStatusrFilter("");
                    setTypeFilter("");
                    setPatientFilter("");
                  }}
                  className="flex flex-row items-center bg-danger text-white px-3 py-1 text-sm rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-4 mx-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                  <div> Reset</div>
                </button>
              </div>
            </div>
          </div>
          <div className=" w-full  px-1 py-3 ">
            <div className="py-4 px-2 shadow-1 bg-white">
              {loadingCases ? (
                <Loader />
              ) : errorCases ? (
                <Alert type="error" message={errorCases} />
              ) : (
                <div className="max-w-full overflow-x-auto ">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className=" bg-[#F3F5FB] text-left ">
                        <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                          ID
                        </th>
                        <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                          Client
                        </th>
                        <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                          Patient Name
                        </th>
                        <th className="min-w-[30px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                          Type
                        </th>
                        <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                          Assigned Provider
                        </th>
                        <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                          Status
                        </th>
                        <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                          Date Created
                        </th>
                        <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max"></th>
                      </tr>
                    </thead>
                    {/*  */}
                    <tbody>
                      {cases?.map((item, index) => (
                        //  <a href={`/cases/detail/${item.id}`}></a>
                        <tr key={index}>
                          <td className=" py-3 px-4 min-w-[120px]  ">
                            <p className="text-black  text-xs w-max  ">
                              #{item.id}
                            </p>
                          </td>
                          <td className=" py-3 px-4 min-w-[120px]  ">
                            <p className="text-black  text-xs w-max  ">
                              {item.assurance?.assurance_name ?? "---"}
                            </p>
                          </td>
                          <td className=" py-3 px-4 min-w-[120px]  ">
                            <p className="text-black  text-xs w-max  ">
                              {item.patient?.full_name ?? "---"}
                            </p>
                          </td>
                          <td className=" py-3 px-4 min-w-[120px]  ">
                            <p className="text-black  text-xs w-max  ">
                              {item.case_type ?? "---"}
                            </p>
                          </td>
                          <td className=" py-3 px-4 min-w-[120px]  ">
                            <p className="text-black  text-xs w-max  ">
                              {item.provider?.full_name ?? "---"}
                            </p>
                          </td>
                          <td className=" py-3 px-4 min-w-[120px]  ">
                            <p className="text-black  text-xs w-max  ">
                              {caseStatus(item.status_coordination)}
                            </p>
                          </td>
                          <td className=" py-3 px-4 min-w-[120px]  ">
                            <p className="text-black  text-xs w-max  ">
                              {formatDate(item.case_date)}
                            </p>
                          </td>
                          <td className=" py-3 px-4 min-w-[120px]  ">
                            <p className="text-black  text-xs w-max flex flex-row  ">
                              <Link
                                className="mx-1 detail-class"
                                to={"/cases-list/detail/" + item.id}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                  />
                                </svg>
                              </Link>
                              <Link
                                className="mx-1 update-class"
                                to={"/cases-list/edit/" + item.id}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                  />
                                </svg>
                              </Link>
                              <div
                                onClick={() => {
                                  setEventType("delete");
                                  setCaseId(item.id);
                                  setIsDelete(true);
                                }}
                                className="mx-1 delete-class cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                  />
                                </svg>
                              </div>
                            </p>
                          </td>
                        </tr>
                      ))}
                      <tr className="h-5"></tr>
                    </tbody>
                  </table>
                  <div className="">
                    <Paginate
                      route={"/dashboard?"}
                      search={""}
                      page={page}
                      pages={pages}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Providers Map
            </h4>
          </div>

          <div className=" w-full  px-1 py-3 ">
            <div className="py-4 px-2 shadow-1 bg-white">
              <div className=" relative">
                <MapContainer
                  center={[0, 0]}
                  zoom={2}
                  style={{ height: "500px", width: "100%" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {cases
                    ?.filter(
                      (provider) =>
                        provider.provider &&
                        provider.provider.location_x &&
                        provider.provider.location_y
                    )
                    .map((provider, index) => (
                      <Marker
                        eventHandlers={{
                          click: () => {
                            setIsOpenMap(true);
                            setProviderMapSelect(provider.provider);
                          }, // Trigger onClick event
                        }}
                        key={index}
                        position={[
                          provider.provider.location_x,
                          provider.provider.location_y,
                        ]}
                      >
                        <Popup>
                          {provider.provider.full_name}
                          <br />
                        </Popup>
                      </Marker>
                    ))}
                </MapContainer>
                {isOpenMap ? (
                  <div className=" absolute top-0 left-0 z-99999  p-2 md:w-1/3 w-2/3 h-full ">
                    <div className="bg-white shadow-1 w-full h-full">
                      <div className=" p-3 float-right ">
                        <button
                          onClick={() => {
                            setIsOpenMap(false);
                            setProviderMapSelect(null);
                          }}
                          className="rounded-full p-1 bg-danger shadow-1 text-white flex items-center w-max "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="pt-10 py-4 px-3">
                        {providerMapSelect && (
                          <div>
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                                  />
                                </svg>
                              </div>

                              <div className="flex-1 px-2">
                                {providerMapSelect.service_type ?? "---"}
                                {providerMapSelect.service_type ===
                                  "Specialists" &&
                                providerMapSelect.service_specialist
                                  ? " : " + providerMapSelect.service_specialist
                                  : ""}
                              </div>
                            </div>
                            {/*  */}
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.full_name ?? "---"}
                              </div>
                            </div>
                            {/*  */}
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.email ?? "---"}
                              </div>
                            </div>
                            {/*  */}
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.phone ?? "---"}
                              </div>
                            </div>
                            {/*  */}
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.address ?? "---"}
                              </div>
                            </div>
                            <p className="text-black  text-xs w-max flex flex-row my-4 ">
                              <Link
                                className="mx-1 update-class "
                                to={
                                  "/providers-list/edit/" + providerMapSelect.id
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-8 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                  />
                                </svg>
                              </Link>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <ConfirmationModal
          isOpen={isDelete}
          message={
            eventType === "delete"
              ? "Are you sure you want to delete this case?"
              : "Are you sure ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else if (eventType === "delete" && caseId !== "") {
              setLoadEvent(true);
              dispatch(deleteCase(caseId));
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsDelete(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default DashboardScreen;
