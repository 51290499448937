import React from "react";
import { Link } from "react-router-dom";

const Paginate = ({ pages, route, search, page }) => {
  console.log(pages);
  console.log(page);
  return (
    pages > 1 && (
      <div className="flex justify-end pt-8 pb-4">
        {[...Array(pages).keys()].map((x) => (
          <Link key={x + 1} to={`${route}page=${x + 1}`}>
            {/* active={x + 1 === page} */}
            <div
              className={` border p-1 size-7 text-xs mr-2 hover:bg-opacity-90 flex items-center justify-center  rounded-md ${
                x + 1 == page ? "bg-primary text-white" : ""
              }`}
            >
              {x + 1}
            </div>
          </Link>
        ))}
      </div>
    )
  );
};

export default Paginate;
