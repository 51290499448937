import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { detailProvider } from "../../redux/actions/providerActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { COUNTRIES } from "../../constants";
import { casesListProvider } from "../../redux/actions/caseActions";
import Paginate from "../../components/Paginate";

function ProviderProfileScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const providerDetail = useSelector((state) => state.detailProvider);
  const {
    loadingProviderInfo,
    errorProviderInfo,
    successProviderInfo,
    providerInfo,
  } = providerDetail;

  const listCases = useSelector((state) => state.caseListProvider);
  const { casesProvider, loadingCasesProvider, errorCasesProvider, pages } =
    listCases;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailProvider(id));
      dispatch(casesListProvider(page, "", id));
    }
  }, [navigate, userInfo, dispatch, id, page]);

  const formatDate = (dateString) => {
    if (dateString && dateString !== "") {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };
  const getIconCountry = (country) => {
    const foundCountry = COUNTRIES.find((option) => option.title === country);

    if (foundCountry) {
      return foundCountry.icon;
    } else {
      return "";
    }
  };

  const caseStatus = (casestatus) => {
    switch (casestatus) {
      case "pending-coordination":
        return "Pending Coordination";
      case "coordinated-missing-m-r":
        return "Coordinated, Missing M.R.";
      case "coordinated-missing-invoice":
        return "Coordinated, Missing Invoice";
      case "waiting-for-insurance-authorization":
        return "Waiting for Insurance Authorization";
      case "coordinated-patient-not-seen-yet":
        return "Coordinated, Patient not seen yet";
      case "fully-coordinated":
        return "Fully Coordinated";
      default:
        return casestatus;
    }
  };

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <a href="/providers-list">
            <div className="flex flex-row  items-center hover:text-black ">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <div className="">Providers List</div>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Profile</div>
        </div>
        {/*  */}
        {loadingProviderInfo ? (
          <Loader />
        ) : errorProviderInfo ? (
          <Alert type={"error"} message={errorProviderInfo} />
        ) : providerInfo ? (
          <>
            <div className="my-5 text-[#303030] text-opacity-60">
              {/* profile */}
              <div className="bg-white rounded shadow-1 my-1 w-full px-3 py-4 flex flex-row items-start text-xs">
                <div className="flex-1 px-5">
                  <div className="flex md:flex-row flex-col">
                    <div className="md:w-1/2 w-full">
                      <div className="text-sm flex flex-row items-center my-1">
                        <div className="font-bold">Provider: </div>
                        <div className="flex-1 px-1">
                          <div>{providerInfo.full_name}</div>
                        </div>
                      </div>
                      <div className="text-sm flex flex-row items-center my-1">
                        <div className="font-bold">Service: </div>
                        <div className="flex-1 px-1">
                          <div>{providerInfo.service_type}</div>
                        </div>
                      </div>
                      <div className="text-sm flex flex-row items-center my-1">
                        <div className="font-bold">Email: </div>
                        <div className="flex-1 px-1">
                          <div>{providerInfo.email}</div>
                        </div>
                      </div>
                      <div className="text-sm flex flex-row items-center my-1">
                        <div className="font-bold">Phone: </div>
                        <div className="flex-1 px-1">
                          <div>{providerInfo.phone}</div>
                        </div>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full">
                      <div className="text-sm flex flex-row items-center my-1">
                        <div className="font-bold">Country: </div>
                        <div className="flex-1 px-1">
                          <div>
                            {getIconCountry(providerInfo.country)}{" "}
                            {providerInfo.country}
                          </div>
                        </div>
                      </div>
                      <div className="text-sm flex flex-row items-center my-1">
                        <div className="font-bold">City: </div>
                        <div className="flex-1 px-1">
                          <div>{providerInfo.city}</div>
                        </div>
                      </div>
                      <div className="text-sm flex flex-row items-center my-1">
                        <div className="font-bold">Address: </div>
                        <div className="flex-1 px-1">
                          <div>{providerInfo.address}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:items-center my-1">
                    <div className="flex-1 px-2 flex flex-row items-center my-1">
                      <div className="flex flex-row items-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5 text-[#32475C] text-opacity-55 mx-1"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                          />
                        </svg>

                        <div className="mx-1">
                          Joined {formatDate(providerInfo.created_at)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* cases */}
              <div className=" w-full  px-1 py-3 ">
                <div className="py-4 px-2 shadow-1 bg-white">
                  {loadingCasesProvider ? (
                    <Loader />
                  ) : errorCasesProvider ? (
                    <Alert type="error" message={errorCasesProvider} />
                  ) : (
                    <div className="max-w-full overflow-x-auto ">
                      <table className="w-full table-auto">
                        <thead>
                          <tr className=" bg-[#F3F5FB] text-left ">
                            <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                              ID
                            </th>
                            <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                              Client
                            </th>
                            <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                              Patient Name
                            </th>
                            <th className="min-w-[30px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                              Type
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                              Assigned Provider
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                              Status
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                              Date Created
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max"></th>
                          </tr>
                        </thead>
                        {/*  */}
                        <tbody>
                          {casesProvider?.map((item, index) => (
                            //  <a href={`/cases/detail/${item.id}`}></a>
                            <tr key={index}>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  #{item.id}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {item.assurance?.assurance_name ?? "---"}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {item.patient?.full_name ?? "---"}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {item.case_type ?? "---"}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {item.provider?.full_name ?? "---"}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {caseStatus(item.status_coordination)}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {formatDate(item.case_date)}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max flex flex-row  ">
                                  <Link
                                    className="mx-1 detail-class"
                                    to={"/cases-list/detail/" + item.id}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                      />
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                      />
                                    </svg>
                                  </Link>
                                </p>
                              </td>
                            </tr>
                          ))}
                          <tr className="h-5"></tr>
                        </tbody>
                      </table>
                      <div className="">
                        <Paginate
                          route={`/providers-list/profile/${id}?`}
                          search={""}
                          page={page}
                          pages={pages}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </DefaultLayout>
  );
}

export default ProviderProfileScreen;
