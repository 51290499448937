export const CASE_LIST_REQUEST = "CASE_LIST_REQUEST";
export const CASE_LIST_SUCCESS = "CASE_LIST_SUCCESS";
export const CASE_LIST_FAIL = "CASE_LIST_FAIL";

export const CASE_ADD_REQUEST = "CASE_ADD_REQUEST";
export const CASE_ADD_SUCCESS = "CASE_ADD_SUCCESS";
export const CASE_ADD_FAIL = "CASE_ADD_FAIL";

export const CASE_DETAIL_REQUEST = "CASE_DETAIL_REQUEST";
export const CASE_DETAIL_SUCCESS = "CASE_DETAIL_SUCCESS";
export const CASE_DETAIL_FAIL = "CASE_DETAIL_FAIL";

export const CASE_UPDATE_REQUEST = "CASE_UPDATE_REQUEST";
export const CASE_UPDATE_SUCCESS = "CASE_UPDATE_SUCCESS";
export const CASE_UPDATE_FAIL = "CASE_UPDATE_FAIL";

export const CASE_DELETE_REQUEST = "CASE_DELETE_REQUEST";
export const CASE_DELETE_SUCCESS = "CASE_DELETE_SUCCESS";
export const CASE_DELETE_FAIL = "CASE_DELETE_FAIL";

export const CASE_COORDINATOR_LIST_REQUEST = "CASE_COORDINATOR_LIST_REQUEST";
export const CASE_COORDINATOR_LIST_SUCCESS = "CASE_COORDINATOR_LIST_SUCCESS";
export const CASE_COORDINATOR_LIST_FAIL = "CASE_COORDINATOR_LIST_FAIL";

export const COMMENT_CASE_LIST_REQUEST = "COMMENT_CASE_LIST_REQUEST";
export const COMMENT_CASE_LIST_SUCCESS = "COMMENT_CASE_LIST_SUCCESS";
export const COMMENT_CASE_LIST_FAIL = "COMMENT_CASE_LIST_FAIL";

export const COMMENT_CASE_ADD_REQUEST = "COMMENT_CASE_ADD_REQUEST";
export const COMMENT_CASE_ADD_SUCCESS = "COMMENT_CASE_ADD_SUCCESS";
export const COMMENT_CASE_ADD_FAIL = "COMMENT_CASE_ADD_FAIL";

export const CASE_ASSIGNED_UPDATE_REQUEST = "CASE_ASSIGNED_UPDATE_REQUEST";
export const CASE_ASSIGNED_UPDATE_SUCCESS = "CASE_ASSIGNED_UPDATE_SUCCESS";
export const CASE_ASSIGNED_UPDATE_FAIL = "CASE_ASSIGNED_UPDATE_FAIL";

export const CASE_INSURANCE_LIST_REQUEST = "CASE_INSURANCE_LIST_REQUEST";
export const CASE_INSURANCE_LIST_SUCCESS = "CASE_INSURANCE_LIST_SUCCESS";
export const CASE_INSURANCE_LIST_FAIL = "CASE_INSURANCE_LIST_FAIL";

export const CASE_PROVIDER_LIST_REQUEST = "CASE_PROVIDER_LIST_REQUEST";
export const CASE_PROVIDER_LIST_SUCCESS = "CASE_PROVIDER_LIST_SUCCESS";
export const CASE_PROVIDER_LIST_FAIL = "CASE_PROVIDER_LIST_FAIL";

export const CASE_PROFILE_LIST_REQUEST = "CASE_PROFILE_LIST_REQUEST";
export const CASE_PROFILE_LIST_SUCCESS = "CASE_PROFILE_LIST_SUCCESS";
export const CASE_PROFILE_LIST_FAIL = "CASE_PROFILE_LIST_FAIL";
